import './Technology.scss';
import React, {useEffect} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SectionTitle from "../../components/SectionTitle";
import {useRecoilState} from "recoil";
import {technologyRecoil, textDataRecoil} from "../../common/data/DataManagementRecoil";
import TechnologyCard from "./technologyCard/TechnologyCard";

const Technology = ()=>{
    useEffect(()=>{
        AOS.init({

        });
    });
    const [textData, setTextData] = useRecoilState(textDataRecoil);
    const [technologyData, setTechnologyData] = useRecoilState(technologyRecoil);
    const props = {
        title: textData.section.sectionTitleTechnology,
        subtitle: textData.section.sectionSubTechnology
    }

    useEffect(() => {
    },[technologyData])

    const renderTechCard = () => {
        const cardItem = technologyData.map((item, index) => {
            return <TechnologyCard key={index} {...item} />
        })
        return cardItem;
    }
    return (
        <div className={"TechnologySection"} id={"TechnologySection"}>
            <SectionTitle {...props} />
            {renderTechCard()}
        </div>
    )
}
export default Technology;
