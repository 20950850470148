import './UpButton.scss';
import {AiOutlineArrowUp} from "react-icons/ai";
import {useEffect, useState} from "react";
const UpButton = ()=>{

  const [scrollY, setScrollY] = useState(0);
  const [scrollActive, setScrollActive] = useState(false);
  const handleScroll = ()=>{
    if(scrollY > 300){
      setScrollY(window.pageYOffset);
      setScrollActive(true);
    }else{
      setScrollY(window.pageYOffset);
      setScrollActive(false);
    }
  }
  useEffect(()=>{
    const scrollListener = ()=>{
      window.addEventListener("scroll", handleScroll);
    }
    scrollListener();
    return () =>{
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const onClick = ()=>{
    window.scrollTo(0, 0);
  }
  return (
    <div className={"UpButton"} onClick={onClick} style={{display: scrollActive?"flex":"none"}}>
      <AiOutlineArrowUp size={20} color={"#ff7070"} />
    </div>
  );
}
export default UpButton;