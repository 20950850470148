import './Header.scss';
import {useEffect, useState, useRef} from "react";
import {FaBars} from "react-icons/fa";
import {HiXMark} from "react-icons/hi2";
const Header = ()=>{
    const clickMenu = (auth: string)=>{
        // const target = document.querySelector(`.${auth}`).getBoundingClientRect().top;
        // window.scrollTo({ top: target, behavior: 'smooth'});
        setMenuMode(1);
        window.location.href = auth;
    }
    const [mobileMenuX, setMobileMenuX] = useState("-250px");
    const [menuMode, setMenuMode] = useState(1);
    const mobileClickMenu = ()=>{
        if(menuMode){
            setMobileMenuX("0");
            setMenuMode(0);
        }else{
            mobileClickClose();
            setMenuMode(1);
        }
    }
    const mobileClickClose = ()=>{
        setMenuMode(1);
        setMobileMenuX("-250px");
    }

    const [scrollY, setScrollY] = useState(0);
    const [scrollActive, setScrollActive] = useState(false);
    const [beforeScrollY, setBeforeScrollY] = useState(0);
    const handleScroll = ()=>{
        if(scrollY > 300 && scrollY <= beforeScrollY){
            setScrollY(window.pageYOffset);
            setScrollActive(true);
        }else{
            setScrollY(window.pageYOffset);
            setScrollActive(false);
        }
        setBeforeScrollY(scrollY);
    }
    useEffect(()=>{
        const scrollListener = ()=>{
            window.addEventListener("scroll", handleScroll);
        }
        scrollListener();
        return () =>{
            window.removeEventListener("scroll", handleScroll);
        };
    });
    return (
        <div className={scrollActive ? "fixeHead" : "HeaderArea"}>
            <div className={"HeaderContentArea"}>
                <div className={"HeaderLogoBox"}>
                    <img alt={"4stech logo"}/>
                </div>
                <div className={"HeaderMenuBox"}>
                    <ul>
                        <li onClick={()=>{clickMenu("#MainSection");}}>
                            Main
                        </li>
                        <li onClick={()=>{clickMenu("#TechnologySection");}}>
                            Technology
                        </li>
                        <li onClick={()=>{clickMenu("#PortfolioSection");}}>
                            Portfolio
                        </li>
                        <li onClick={()=>{clickMenu("#PartnersSection");}}>
                            Partners
                        </li>
                        <li onClick={()=>{clickMenu("#ContactSection");}}>
                            Contact
                        </li>
                    </ul>
                </div>
                <div className={"HeaderMenuBoxMobile"} onClick={mobileClickMenu}>
                    <FaBars size={"30"} color={"black"}></FaBars>
                </div>
            </div>
            <div className={"mobileMenuArea"} style={{
                left: mobileMenuX
            }}>
                <div onClick={()=>{clickMenu("#MainSection"); mobileClickClose();}}>Main</div>
                <div onClick={()=>{clickMenu("#TechnologySection"); mobileClickClose();}}>Technology</div>
                <div onClick={()=>{clickMenu("#PortfolioSection"); mobileClickClose();}}>Portfolio</div>
                <div onClick={()=>{clickMenu("#PartnersSection"); mobileClickClose();}}>Partners</div>
                <div onClick={()=>{clickMenu("#ContactSection"); mobileClickClose();}}>Contact</div>
                <span onClick={mobileClickClose}>
                    <HiXMark size={25}/>
                </span>
            </div>
        </div>
    );
}

export default Header;