import './Main.scss';
import React, {useEffect, useState} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../../components/Header";
import simple from "../../asset/simple.png";
import speed from "../../asset/speed.png";
import skill from "../../asset/skill.png";
import strength from "../../asset/strength.png";
import {GiMuscleUp} from "react-icons/gi";
import {useRecoilState} from "recoil";
import {portfolioRecoil, textDataRecoil} from "../../common/data/DataManagementRecoil";
const Main = ()=>{
    const [textData, setTextData] = useRecoilState(textDataRecoil);
    const mainText = textData.main;
    useEffect(()=>{
        AOS.init({
            duration: 1500
        });
    });
    return (
        <div className={"MainSection"} id={"MainSection"}>
            <Header></Header>
            <div className={"mainVisual"}>
                <div className={"mainTitleArea"}>
                    <h1 data-aos={"fade-right"}>
                        비즈니스 요구에 맞춘 소프트웨어 전문 개발 업체로써
                    </h1>
                    <h1 data-aos={"fade-left"} data-aos-delay={500}>
                        고객의 미래가치를 높여 드립니다.
                    </h1>
                    <div className={"titleUnderLine"}></div>
                </div>
            </div>
            <div className={"fourcardSection"}>
                <div>
                    <h1>
                        {mainText.mainTitle1}
                    </h1>
                    <h2>
                        {mainText.mainSubTitle1}
                    </h2>
                </div>
                <div>
                    <div>
                        <img src={simple}></img>
                        <h3>{mainText.fourcardTitle1}</h3>
                        <p>{mainText.fourcardDescription1}</p>
                    </div>
                    <div>
                        <img src={speed}></img>
                        <h3>{mainText.fourcardTitle2}</h3>
                        <p>{mainText.fourcardDescription2}</p>
                    </div>
                    <div>
                        <img src={skill}></img>
                        <h3>{mainText.fourcardTitle3}</h3>
                        <p>{mainText.fourcardDescription3}</p>
                    </div>
                    <div>
                        <img src={strength}></img>
                        <h3>{mainText.fourcardTitle4}</h3>
                        <p>{mainText.fourcardDescription4}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Main;
