import './SectionTitle.scss';
interface SectionTitleProps{
  title: string,
  subtitle: string
}
const SectionTitle = (props: SectionTitleProps)=>{
  return (
    <div className={"SectionTitleArea"}>
      <h1>
        {props.title}
      </h1>
      <h2>
        {props.subtitle}
      </h2>
    </div>
  );
}
export default SectionTitle;