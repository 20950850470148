import React, {useCallback, useEffect, useState} from 'react';
import './App.scss';
import Main from './section/Main';
import {
  portfolioRecoil,
  technologyRecoil,
  testRecoil,
  textDataRecoil
} from "./common/data/DataManagementRecoil";
import {useRecoilState} from "recoil";
import fileDB from "./common/data/FileDB.json";
import UpButton from "./components/UpButton";
import Footer from "./components/Footer";
import Portfolio from "./section/Portfolio";
import Technology from "./section/Technology";
import Partners from "./section/Partners";
import Contact from "./section/Contact";
import smoothscroll from "smoothscroll-polyfill";
function App() {
    const [portfolioData, setPortfolioData] = useRecoilState(portfolioRecoil);
    const [technologyData, setTechnologyData] = useRecoilState(technologyRecoil);
    const [textData, setTextData] = useRecoilState(textDataRecoil);
    useEffect(()=>{
       smoothscroll.polyfill();
       setPortfolioData(fileDB.portfolio_data);
       setTechnologyData(fileDB.technology_data);
       setTextData(fileDB.text_data[0]);
    }, []);

    return (
        <div className="App">
            <Main/>
            <Technology></Technology>
            <Portfolio/>
            <Partners/>
            <Contact/>
            <Footer></Footer>
            <UpButton />
        </div>
    );
}

export default App;