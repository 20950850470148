import './Partners.scss';
import React, {useEffect} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {useRecoilState} from "recoil";
import {textDataRecoil} from "../../common/data/DataManagementRecoil";
import SectionTitle from "../../components/SectionTitle";
import SalesSlider from "../../components/SalesSlider/SalesSlider";
const Partners = ()=>{ //Work with
    useEffect(()=>{
        AOS.init({

        });
    });
    const [textData, setTextData] = useRecoilState(textDataRecoil);
    const sectionText = textData.section;
    const props = {
        title: sectionText.sectionTitlePartners,
        subtitle: sectionText.sectionSubPartners
    }
    const partnersText = textData.partners;
    return (
        <div className={"PartnersSection"} id={"PartnersSection"}>
            <SectionTitle {...props}/>
            <SalesSlider></SalesSlider>
        </div>
    )
}
export default Partners;
