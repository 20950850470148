import "./Footer.scss";
import { Cascader } from 'antd';


const Footer = ()=>{
    interface Option {
        value: string | number;
        label: string;
        children?: Option[];
    }
    const options: Option[] = [
        {
            value: "포에스텍",
            label: '포에스텍',

        },
        {
            value: "쏘드라이브",
            label: '쏘드라이브',

        }
    ];
    const onChange: any = (value: string[] | number[]) => {
        switch (value[0]){
            case "포에스텍":
                window.location.href = "/";
                break;
            case "쏘드라이브":
                // window.location.href = "http://www.sodrive.co.kr/";
                window.open("http://www.sodrive.co.kr/", "_blank")
                break;
            default:
                window.location.href = "/";
                break;
        }
    };
    return (
        <div className={"FooterArea"}>
            <div>
                <div className={"footerLogoArea"}>
                    <img />
                </div>
                <div>
                    <p>
                        (주)포에스텍
                    </p>
                    <p>
                        대표 이사 : 김용구 | TEL. 02-6013-6300<br/>FAX. 02-6008-8556 | 사업자등록번호 215-87-44300
                    </p>
                    <p>
                        서울시 성동구 성수이로 7길 27 309호<br/> (성수동 2가, 서울숲 코오롱 디지털 타워 2차)
                    </p>
                    <p style={{color:"gray"}}>
                        COPYRIGHT ⓒ 4stech. ALL RIGHTS RESERVED
                    </p>
                </div>
            </div>
            <div>
                <Cascader className={"cascader"} size={"middle"} options={options} placeholder="사이트맵" onChange={onChange}/>
            </div>
        </div>
    );
}
export default Footer;